<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M10.2308 13.3043C10.2308 12.8241 10.6268 12.4348 11.1154 12.4348H14.6538C15.1424 12.4348 15.5385 12.8241 15.5385 13.3043C15.5385 13.7846 15.1424 14.1739 14.6538 14.1739H11.1154C10.6268 14.1739 10.2308 13.7846 10.2308 13.3043Z"
    />
    <path
      d="M11.1154 15.0435C10.6268 15.0435 10.2308 15.4328 10.2308 15.913C10.2308 16.3933 10.6268 16.7826 11.1154 16.7826H14.6538C15.1424 16.7826 15.5385 16.3933 15.5385 15.913C15.5385 15.4328 15.1424 15.0435 14.6538 15.0435H11.1154Z"
    />
    <path
      d="M10.2308 18.5217C10.2308 18.0415 10.6268 17.6522 11.1154 17.6522H14.6538C15.1424 17.6522 15.5385 18.0415 15.5385 18.5217C15.5385 19.002 15.1424 19.3913 14.6538 19.3913H11.1154C10.6268 19.3913 10.2308 19.002 10.2308 18.5217Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.03846 15.6418C1.97771 14.9875 0.5 13.2206 0.5 11.1281C0.5 8.90306 2.1862 7.04587 4.4565 6.50357C5.49339 3.87411 8.28693 2 11.5592 2C14.771 2 17.5216 3.80557 18.6026 6.3587C21.3329 6.55544 23.5 8.60507 23.5 11.1281C23.5 13.7764 21.1126 15.903 18.1923 15.913L18.1923 19.6704C18.1895 20.2747 17.9556 20.861 17.5294 21.3C17.1019 21.7403 16.514 21.997 15.8908 22L15.8865 22H6.23063C4.91479 22 4.03846 20.8445 4.03846 19.6655V15.6418ZM6.05894 7.44274C6.70583 5.27872 8.91751 3.67302 11.5592 3.67302C14.1875 3.67302 16.3903 5.26258 17.0495 7.4102L17.2476 8.05542L17.9834 8.0207C18.0457 8.01776 18.1085 8.01626 18.1717 8.01626C20.1113 8.01626 21.66 9.42132 21.66 11.1281C21.66 12.8289 20.1223 14.23 18.1923 14.24L18.1923 12.1596L18.1923 12.1557C18.1895 11.5514 17.9556 10.9651 17.5294 10.5261C17.1019 10.0858 16.514 9.82912 15.8908 9.82609H6.23063C4.91479 9.82609 4.03846 10.9816 4.03846 12.1605V13.7594C3.01555 13.2353 2.34 12.263 2.34 11.1281C2.34 9.5779 3.61669 8.27696 5.30515 8.05092L5.90097 7.97116L6.05894 7.44274ZM5.80769 12.1605C5.80769 11.9612 5.87988 11.8003 5.971 11.6982C6.0606 11.5978 6.15435 11.5652 6.23063 11.5652H7.15398C7.23027 11.5652 7.32402 11.5978 7.41362 11.6982C7.50473 11.8003 7.57692 11.9612 7.57692 12.1605V19.6655C7.57692 19.8649 7.50473 20.0258 7.41362 20.1279C7.32402 20.2283 7.23027 20.2609 7.15398 20.2609H6.23063C6.15435 20.2609 6.0606 20.2283 5.971 20.1279C5.87988 20.0258 5.80769 19.8649 5.80769 19.6655V12.1605ZM9.27191 20.2609H15.8838C16.0129 20.2598 16.1452 20.2068 16.2495 20.0993C16.3553 19.9903 16.4219 19.8347 16.4231 19.6641V12.162C16.4219 11.9914 16.3553 11.8358 16.2495 11.7268C16.1451 11.6193 16.0129 11.5663 15.8837 11.5652H9.27191C9.32068 11.7592 9.34615 11.9596 9.34615 12.1605V19.6655C9.34615 19.8665 9.32068 20.0668 9.27191 20.2609Z"
    />
  </svg>
</template>
